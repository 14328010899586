import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Dibuat',
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.created_at}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        sortable: false,
        width: 250,
        template: function(data) {
          // return `<span class="font-weight-bolder">${data.name}</span>`;
          var html_output = `
            <div class="d-flex align-items-center">
              <div class="symbol symbol-40 symbol-sm flex-shrink-0">
                <img class="" src="${data.user_avatar_url}" alt="photo">
              </div>
              <div class="ml-4">
                <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">${data.name}</div>
                <a href="#" class="text-muted font-weight-bold text-hover-primary">${data.company_name}</a>
              </div>
            </div>
          `;

          return html_output;
        }
      },
      {
        field: 'certificate',
        title: 'Nama Sertifikat',
        sortable: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.certificate}</span>`;
        }
      },
      {
        field: 'email',
        title: 'Email',
        sortable: false,
        width: 250,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.email}</span>`;
        }
      },
      {
        field: 'status',
        title: 'status',
        sortable: false,
        width: 150,
        template: function(data) {
          const status = data.status;

          if (status){
            return `<span 
              style="font-size:12px;" 
              class="label label-inline label-success font-weight-bold" 
              data-controller="tooltip" 
              title="Berhasil di Approve"
            >
              Approved
            </span>`;
          }else{
            return `<span 
              style="font-size:12px;" 
              class="label label-inline label-danger font-weight-bold" 
              data-controller="tooltip" 
              title="Berhasil di Approve"
            >
              Waiting Approval
            </span>`;
          }
          
        }
      },
    ]
  }
}
